.event-sub-header {
  .programme-desc-full,
  .programme-desc-personal {
    margin-left: 1em;
  }
}

@media print {
  .no-print{
    display:none;
  }
}
