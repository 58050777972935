.event-page-header {
    .button-bar {
        .i-button.icon-layout {
            display: none;
        }
    }
}

.timetable-item-body {
    .timetable-item-header {
        .timetable-type {
            color: #707479;
            font-weight: bold;
            font-size: 1.1em;
            padding-top: 0.5rem;
            line-height: 1rem;
            padding-right: 0.2rem;
        }
    }
}

.timetable-item-header {
    font-size: 15px;
}

.meeting-timetable {
    .subcontrib-list {
        margin-top: 1em;
    }
}

.day-header .day-title {
    font-variant: normal;
}

.timetable-contrib .timetable-title {
    color: #101820;
}

.speaker-list {
    color: #707479;
    font-size: 14px;
}

.subcontrib-list {
    .timetable-title {
        font-size: 14px;
    }
    .timetable-title {
        font-weight: bold;
    }
}

#conflicts .conflict {
    font-size: 1.2em;
    color: #707479;
}

#conflicts .contribution {
    font-size: 1.2em;
    color: #707479;
    margin: 5px 0 0 20px;
}

// the event display, i.e. the main event homepage, on meetings
// is also the timetable.
.timetable-timetable.eventtype-conference,
.events-display.eventtype-meeting {
    .header {
        display: none;
    }
    .event-wrapper {
        width: auto;
        max-width: 1000px;
        padding: 0 10px;
        .event-header,
        .event-time-data time,
        .event-location .text {
            background-color: #101820;
        }
        .event-details-content {
            a {
                text-decoration: none;
                color: #101820;
            }
        }
    }
}

.meeting-timetable .timetable-item {
    display: block;
    @media screen and (min-width: 600px) {
        display: flex;
    }
}

.timetable-item-header {
    margin-bottom: 0.8rem;
    display: block;
    & > * {
        display: block;
    }
    @media screen and (min-width: 600px) {
        display: flex;
    }
}

.location-info {
    padding-top: 0.5rem;
    text-align: left;
    @media screen and (min-width: 600px) {
        text-align: right;
    }
}

.timetable-time {
    color: #101820 !important;
    .end-time {
        color: #101820;
    }
    &.top-level .start-time {
        background-color: #FFC845;
        color: #101820 !important;
    }
}
