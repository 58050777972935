// Hide sort button on people form fields.
.panel-people-form + .i-box-footer {
    span.alpha-order-switch {
        display: none;
    }
}

#panel-abstract-form {
    #form-group-submitted_for_tracks {
        display: none;
    }
}

.panel-abstracts {
    margin-top: 1rem;
}

.dialog-panel-abstract {
    max-width: 700px;
}
