body.events-display {
  .infoline {
    &.material.material-list {
      display: none;
    }
  }
  .event-details-row {
    .icon-attachment.inline-attachments-icon,
    .event-details-content.material-list {
      display: none;
    }
  }
}
